<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar :title="$t('account_detail')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#ddb285" @click="back()" />
				</template>
			</van-nav-bar>
			<div class="info">
				<p class="title">{{ $t("win_lost") }}</p>
				<p class="value">{{ this.win_money.toLocaleString() }}</p>
				<p class="tip">{{ $t("formula") }}</p>
			</div>

			<div class="content">
				<div class="datalist">
					<div class="datalistitem">
						<div class="datalistitemValue">{{ this.personalreport.play_money.toLocaleString() }}</div>
						<div class="datalistitemKey">{{ $t("money_choose") }}</div>
						<div class="datalistitemRightLine"></div>
					</div>
					<div class="datalistitem">
						<div class="datalistitemValue">{{ this.personalreport.recharge.toLocaleString() }}</div>
						<div class="datalistitemKey">{{ $t("money_recharge") }}</div>
						<div class="datalistitemRightLine"></div>
					</div>
					<div class="datalistitem">
						<div class="datalistitemValue">{{ this.personalreport.withdrawal.toLocaleString() }}</div>
						<div class="datalistitemKey">{{ $t("money_withdraw") }}</div>
						<div class="datalistitemRightLine"></div>
					</div>
					<div class="datalistitem">
						<div class="datalistitemValue">{{ this.personalreport.win_money.toLocaleString() }}</div>
						<div class="datalistitemKey">{{ $t("money_win") }}</div>
						<div class="datalistitemRightLine"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			win_money: 0,
			personalreport: {},
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		getPersonalreport() {
			this.$http({
				method: "get",
				url: "user_get_personalreport",
			}).then(res => {
				if (res.code === 200) {
					this.personalreport = res.data;
					this.win_money = this.personalreport.win_money - this.personalreport.play_money;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({path: "/Login"});
		} else {
			this.getPersonalreport();
		}
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.container .header {
	color: #8a5623;
}
.page {
	min-height: 100vh;
	background: #282828;
}
.container .header .info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 20px;
	padding-top: 10px;
	margin: auto;
}
.container .header .info .title {
	font-size: 18px;
	color: #e5e5e5;
}
.container .header .info .value {
	margin: 10px auto;
	color: #ddb285;
	font-size: 32px;
	border-bottom: 1px solid #fff;
}
.container .header .info .tip {
	font-size: 14px;
	color: #e5e5e5;
}
.container .content {
	flex: 1;
	background: #282828;
}
.container .content .datalist {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
}
.container .content .datalist .datalistitem {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 33.3%;
}
.container .content .datalist .datalistitem .datalistitemValue {
	color: #ddb285;
	font-size: 22px;
	margin-bottom: 0px;
	margin-top: 10px;
}
.container .content .datalist .datalistitem .datalistitemKey {
	color: #979799;
	font-size: 15px;
	margin-bottom: 10px;
	margin-top: 10px;
}
</style>
