<template>
	<div class="container page">
		<van-nav-bar :title="$t('setting')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#ddb285" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="items">
			<div class="item van-hairline--bottom" @click="toInfomation()">
				<div class="left">{{ $t("setting_info") }}</div>
				<van-icon name="arrow" />
			</div>
			<div class="item van-hairline--bottom" @click="toLoginPassword()">
				<div class="left">{{ $t("change_pwd") }}</div>
				<van-icon name="arrow" />
			</div>
			<div class="item van-hairline--bottom" @click="toLanguage()">
				<div class="left">{{ $t("language") }}</div>
				<van-icon name="arrow" />
			</div>
			<!--<div class="item van-hairline--bottom" @click="toPayPassword()">
          <div class="left">{{ $t('paypwd') }}</div>
          <div class="right">
            <span class="desc">{{this.userInfo.paypassword}}</span>
            <van-icon name="arrow" />
          </div>
        </div>-->
		</div>
		<van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{ $t("logout") }}</van-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			userInfo: {},
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		/*toPayPassword(){
      if(this.userInfo.paypassword !== "Chưa thiết lập"){
        this.$toast(this.$t('text_cannot_set'));
      }else {
        this.$router.push({path:'/SetPayPassword'});
      }
    },*/
		toLoginPassword() {
			this.$router.push({path: "/SetLoginPassword"});
		},
		toLanguage() {
			this.$router.push({path: "/Language"});
		},
		toInfomation() {
			this.$router.push({path: "/Infomation"});
		},
		loginout() {
			localStorage.clear();
			this.$router.push({path: "/Mine"});
		},
		toServicePage() {
			this.$router.push("ServicePage");
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					if (res.data.paypassword) {
						this.userInfo.paypassword = this.$t("done_set");
					} else {
						this.userInfo.paypassword = this.$t("not_set");
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({path: "/Login"});
		} else {
			this.getUserInfo();
		}
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.container .items {
	background: #282828;
	font-size: 16px;
	color: #ddb285;
	padding: 0 20px;
}
.container .items .item {
	padding: 20px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom-width: 10px;
}
.container .items .van-hairline--bottom::after {
	border-bottom-width: 3px;
}
.container .sign-out {
	margin: 20px auto;
	max-width: 400px;
	height: 40px;
	line-height: 40px;
	border-radius: 20px;
	font-size: 16px;
	font-weight: bolder;
	border: none;
	background: linear-gradient(#fde3ca, #e7b486);
	color: #8a5623;
}
.container .item .desc {
	font-size: 16px;
	font-weight: 700;
	color: #979799;
}
.container .item .right {
	display: flex;
	flex-direction: row;
	align-items: center;
}
</style>
