<template>
	<div class="container page">
		<van-nav-bar :title="$t('voting') + ' ' + this.lottery.name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#ddb285" @click="back()" />
			</template>
			<!--      <template #right>-->
			<!--        <div class="right">{{ $t("history_play") }}</div>-->
			<!--      </template>-->
		</van-nav-bar>
		<div class="record">
			<span class="span-text" @click="$router.push({path: '/GameRecord'})" style="position:absolute;top:60px;right:10px;color:#eee;cursor: pointer;">{{ $t("history_play") }}</span>
			<div class="period">
				<van-image class="cover" :src="this.lottery.ico">
					<template v-slot:loading>
						<van-loading type="spinner" />
					</template>
				</van-image>
				<span class="period-number"
					><b>{{ this.lottery.next_expect }}</b>
				</span>
				<div class="next-number">
					<van-count-down :time="time" @finish="check()" />
				</div>
			</div>
			<div class="linear-gradient" style="background: linear-gradient(to right, rgba(126, 86, 120, 0), #ddb285, rgba(126, 86, 120, 0));"></div>
			<div class="recent">
				<div class="kuaisan-ball left" style="color:#ddb285">
					<span
						>{{ $t("prev_period") }} <b>{{ this.lottery.now_expect }}</b></span
					>
					<span class="res-des middle" style="color:#fff;padding-right:3px">{{ this.lottery_peilv_list[this.size - 1].name }}</span>
					<span class="res-des middle" style="color:#fff">{{ this.lottery_peilv_list[this.double - 1].name }}</span>
				</div>
				<van-icon name="arrow-down" :class="{up: active, down: !active}" @click="active ? (active = false) : (active = true)" />
			</div>
		</div>
		<div class="history_popup"></div>
		<div class="wrapper">
			<div class="options-bar">
				<div class="game">
					<div class="tips" style="display:none">
						<p class="odds">【{{ this.lottery.desc }}】</p>
						<div class="play-tip">
							<van-icon name="more-o" />
							<!--                  <span class="span-text" @click="playgame = true">Mẹo</span>-->
							<span class="span-text" @click="$router.push({path: '/GameRecord'})">{{ $t("history_play") }}</span>
							<van-popup class="mask" get-container="body" v-model="playgame">
								<div class="play-type-tip">
									<div class="title">Luật chơi</div>
									<div class="wrapper">
										<div class="item">
											<van-icon name="info-o" />
											<div class="content">
												<p class="content-title">Mẹo trò chơi</p>
												<p class="content-detail">
													Chọn số từ các hình thức tùy chọn và giá trị để yêu thích
												</p>
											</div>
										</div>
										<div class="item">
											<van-icon name="comment-o" />
											<div class="content">
												<p class="content-title">Cách chiến thắng</p>
												<p class="content-detail">...</p>
											</div>
										</div>
										<div class="item">
											<van-icon name="description" />
											<div class="content">
												<p class="content-title">Ví dụ</p>
												<p class="content-detail">...</p>
											</div>
										</div>
									</div>
								</div>
							</van-popup>
						</div>
					</div>
					<div class="linear-gradient" style="background: linear-gradient(to right, rgba(126, 86, 120, 0), #ddb285, rgba(126, 86, 120, 0));"></div>
					<div class="sumValueTwoSides">
						<div class="rectangle large" :class="{active: choose[v.type]}" v-for="(v, key) in lottery_peilv_list" :key="key" @click="choosePlay(v.type, v.name)">
							<div class="wrapper">
								<div class="content">
									<p class="name-text large">{{ v.name }}</p>
									<p class="odd-text large">{{ v.proportion }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-bar">
				<div class="bar">
					<div class="left">
						<div class="item" @click="shopping ? (shopping = false) : (shopping = true)">
							<van-icon name="cart-o" class="jixuanico" />
							<!--<span class="text">{{ $t("select") }}</span>-->
						</div>
						<div class="line"></div>
					</div>
					<div class="mid">
						<span class="text">{{ $t("your_point") }}</span>
						<span class="text num">{{ Number(this.userInfo.money).toLocaleString() }}</span>
					</div>
					<div class="right" @click="jiesuan()">
						{{ $t("voting") }}
					</div>
				</div>
				<div class="wrapper" :class="{active: shopping}">
					<div class="item">
						<span class="label">{{ $t("content") }}：</span>
						<div class="bet-number">{{ this.shopchoose }}</div>
						<van-icon name="arrow-down" :class="{up: !shopping, down: shopping}" @click="shopping ? (shopping = false) : (shopping = true)" />
					</div>
					<div class="item">
						<span class="label">{{ $t("money_choose") }}：</span>
						<div class="amount-wrapper">
							<van-field v-model="money" type="digit" :placeholder="$t('pls_money')" />
							<!--<span class="label">đ</span>-->
						</div>
					</div>
					<div class="item" style="justify-content: space-between;">
						<div class="part">
							<span>{{ $t("subtotal") }}</span>
							<span class="number">{{ this.formData.length }}</span>
							<span>{{ $t("select") }}</span>
						</div>
						<div class="part">
							<span>{{ $t("total") }}</span>
							<span class="number">{{ Number(this.formData.length * this.money).toLocaleString() }}</span>
							<!--<span>đ</span>-->
						</div>
					</div>
				</div>
			</div>
			<van-popup v-model="jiesuanpage" get-container="body">
				<div class="confirm-order-modal">
					<div class="head van-hairline--bottom">
						<p class="text">{{ $t("confirm") }}</p>
					</div>
					<ui class="list">
						<li v-for="(v, key) in formData" :key="key" class="lise-item van-hairline--bottom">
							<div class="main">
								<p class="bet-name">{{ v.name }}</p>
								<p class="detail-text">1 x {{ Number(money).toLocaleString() }} = {{ Number(money).toLocaleString() }}</p>
							</div>
							<van-icon @click="clearChooes(v.type)" name="close" />
						</li>
					</ui>
					<div class="sub-bar">
						<van-button class="item cancel-btn" type="default" @click="allClear()">{{ $t("cancel") }}</van-button>
						<van-button class="item sub-btn" type="default" @click="doSub()">{{ $t("confirm") }}</van-button>
					</div>
				</div>
			</van-popup>
			<van-popup v-model="active" position="top" :style="{height: '70%'}">
				<van-pull-refresh v-model="isLoading">
					<div class="wrapper">
						<div class="item">
							<div class="left font-weight">{{ $t("period") }}</div>
							<div class="right font-weight">{{ $t("result") }}</div>
						</div>
						<div class="item" v-for="(v, key) in lottery_list" :key="key">
							<div class="left font-weight">{{ v.expect }}</div>
							<div class="right font-weight">
								<div class="kuaisan-ball left">
									<span class="res-des middle">{{ lottery_peilv_list[v.opencode[0] - 1].name }}</span>
									<span class="res-des middle">{{ lottery_peilv_list[v.opencode[1] - 1].name }}</span>
								</div>
							</div>
						</div>
					</div>
				</van-pull-refresh>
			</van-popup>
		</div>
	</div>
</template>

<script>
var time;
var count = 0;
export default {
	data() {
		return {
			jiesuanpage: false,
			choose: {
				"1": false,
				"2": false,
				"3": false,
				"4": false,
			},
			playgame: false,
			shopping: false,
			isLoading: false,
			play: {},
			lottery_peilv_list: {},
			lottery_list: {},
			active: false,
			userInfo: {},
			lottery: {},
			shanzi_1: "0",
			shanzi_2: "0",
			shanzi_3: "0",
			sum: 0,
			size: "",
			double: "",
			time: 0,
			shopchoose: this.$t("not_select"),
			gameitem: "",
			formData: [],
			money: "",
		};
	},
	methods: {
		back() {
			//return window.history.back();
			return this.$router.push({path: "/Home"});
		},
		doSub() {
			if (this.money === "0") {
				this.$toast(this.$t("pls_money"));
				return false;
			}
			if (this.formData.length === 0) {
				this.$toast(this.$t("pls_select"));
				return false;
			} else if (this.money === "") {
				this.$toast(this.$t("pls_money"));
				return false;
			} else {
				if (this.userInfo.money - this.money * this.formData.length < 0) {
					this.$toast(this.$t("not_enough"));
					return false;
				} else {
					this.$http({
						method: "post",
						data: {
							item: this.gameitem,
							money: this.money,
							lid: this.lottery.id,
							mid: this.userInfo.id,
							expect: this.lottery.now_expect,
						},
						url: "game_place_order",
					}).then(res => {
						if (res.code === 200) {
							this.$toast(res.msg);
							this.allClear();
							this.getUserInfo();
						} else if (res.code === 401) {
							this.$toast(res.msg);
						}
					});
					return true;
				}
			}
		},
		allClear() {
			for (var i = 0; i < this.formData.length; i++) {
				this.choose[this.formData[i]["type"]] = false;
			}
			this.formData.length = 0;
			this.money = "";
			this.shopchoose = this.$t("not_select");
			this.gameitem = "";
			this.shopping = false;
			this.jiesuanpage = false;
		},
		jiesuan() {
			if (this.formData.length === 0) {
				this.$toast(this.$t("pls_select"));
				return false;
			} else if (this.money === "") {
				this.$toast(this.$t("pls_money"));
				return false;
			} else {
				this.jiesuanpage ? (this.jiesuanpage = false) : (this.jiesuanpage = true);
			}
		},
		clearChooes(type) {
			for (var i = 0; i < this.formData.length; i++) {
				if (type === this.formData[i]["type"]) {
					this.formData.splice(i, 1);
					this.choose[type] = false;
				}
			}
			if (this.formData.length >= 1) {
				for (var j = 0; j < this.formData.length; j++) {
					if (j === 0) {
						this.shopchoose = this.formData[j]["name"];
						this.gameitem = this.formData[j]["type"];
					} else {
						this.shopchoose += "," + this.formData[j]["name"];
						this.gameitem += "," + this.formData[j]["type"];
					}
				}
			} else {
				this.shopchoose = this.$t("not_select");
				this.gameitem = "";
				this.shopping = false;
			}
			if (this.formData.length === 0) {
				this.jiesuanpage = false;
			}
		},
		choosePlay(type, name) {
			if (this.choose[type] === true) {
				this.choose[type] = false;
				for (var i = 0; i < this.formData.length; i++) {
					if (type === this.formData[i]["type"]) {
						this.formData.splice(i, 1);
					}
				}
			} else if (this.choose[type] === false) {
				this.formData.push({name: name, type: type});
				this.choose[type] = true;
			}
			if (this.formData.length === 1) {
				this.shopping = true;
			}
			if (this.formData.length >= 1) {
				for (var j = 0; j < this.formData.length; j++) {
					if (j === 0) {
						this.shopchoose = this.formData[j]["name"];
						this.gameitem = this.formData[j]["type"];
					} else {
						this.shopchoose += "," + this.formData[j]["name"];
						this.gameitem += "," + this.formData[j]["type"];
					}
				}
			} else {
				this.shopchoose = this.$t("not_select");
				this.gameitem = "";
				this.shopping = false;
			}
		},
		check() {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				time = window.setInterval(() => {
					setTimeout(() => {
						this.getUserInfo();
						this.getLotteryInfo();
						this.getLotteryList();
						count++;
						if (count > 5) {
							clearInterval(time);
							count = 0;
						}
					}, 0);
				}, 300);
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("renew"));
				this.getLotteryList();
				this.isLoading = false;
			}, 200);
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryPeilv() {
			this.$http({
				method: "get",
				data: {id: this.$route.query.id},
				url: "lottery_get_peilv",
			}).then(res => {
				if (res.code === 200) {
					this.lottery_peilv_list = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryList() {
			this.$http({
				method: "get",
				data: {key: this.$route.query.key},
				url: "lottery_get_one_list",
			}).then(res => {
				if (res.code === 200) {
					this.lottery_list = res.data;
					this.getLotteryPeilv();
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
		getLotteryInfo() {
			this.$http({
				method: "get",
				data: {key: this.$route.query.key},
				url: "lottery_get_one_list",
			}).then(res => {
				if (res.code === 200) {
					if (this.time / 1000 === 59) {
						this.$toast(this.$t("start_period") + " " + this.lottery.now_expect);
					}
					this.size = res.data[0].opencode[0];
					this.double = res.data[0].opencode[1];
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
			this.$http({
				method: "get",
				data: {key: this.$route.query.key},
				url: "lottery_get_info",
			}).then(res => {
				if (res.code === 200) {
					if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
						this.$toast(this.$t("pls_contact"));
						this.$router.push({path: "/Home"});
						return false;
					}
					this.lottery = res.data;
					this.time = res.data.second * 1000;
					if (this.time / 1000 === 59) {
						this.$toast(this.$t("start_period") + " " + this.lottery.now_expect);
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({path: "/Login"});
		} else {
			this.getUserInfo();
			this.getLotteryInfo();
			this.getLotteryList();
		}
	},
	destroyed() {
		clearInterval(time);
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.nav-bar .right {
	padding-left: 8px;
	padding-right: 8px;
	color: #fff;
	font-size: 14px;
	border-radius: 10px;
	border: 2px solid #fff;
	line-height: 30px;
}
.record {
	padding-left: 10px;
	padding-right: 10px;
	background: #282828;
	box-shadow: 0 2px 2px 0 #cacaca;
	z-index: 1;
}
.record .period {
	display: flex;
	align-items: center;
	padding: 20px 0;
}
.record .period .cover {
	width: 60px;
	height: 60px;
}
.record .period .period-number {
	flex: 1;
	margin-left: 10px;
	margin-right: 10px;
	height: 40px;
	line-height: 40px;
	font-size: 18px;
	color: #ddb285;
}
.van-count-down {
	color: #bb752b;
	font-size: 20px;
	margin-top: 10px;
	float: right;
	clear: both;
}
.linear-gradient {
	width: 100%;
	height: 2px;
}
.record .recent {
	display: flex;
	align-items: center;
	height: 50px;
}
.kuaisan-ball .left {
	justify-content: flex-start;
}
.kuaisan-ball {
	flex: 1;
	display: flex;
	align-items: center;
	color: #fff;
}

.kuaisan-ball .res-img {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}
.kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	color: #fff;
}
.kuaisan-ball .res-des.middle {
	width: 100px;
	font-size: 16px;
	text-align: center;
}
.van-icon {
	font-size: 20px;
}
.down {
	transition: all 0.5s;
}
.up {
	transform: rotate(180deg);
	transition: all 0.5s;
}
.wrapper {
	position: relative;
	flex: 1;
	overflow: hidden;
}
.options-bar {
	display: flex;
	align-items: center;
}
.options-bar .game {
	flex: 1;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.options-bar .game .tips {
	display: flex;
	align-items: center;
	height: 50px;
	padding: 0 15px;
}
.options-bar .game .tips .odds {
	flex: 1;
	font-size: 15px;
	font-weight: 500;
	color: #ddb285;
}
.options-bar .game .tips .play-tip {
	display: flex;
	align-items: center;
	height: 100%;
}
::v-deep .van-icon-more-o {
	color: #ddb285;
	font-size: 16px;
}
.options-bar .game .tips .play-tip .span-text {
	margin-left: 5px;
	font-size: 15px;
	font-weight: bolder;
	color: #ddb285;
}
.linear-gradient {
	width: 100%;
	height: 2px;
}
.sumValueTwoSides {
	display: flex;
	padding: 10px 0 200px;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.rectangle {
	overflow: hidden;
}
.rectangle.large {
	width: 40%;
	margin: 10px 0 10px 6%;
	border-radius: 10px;
}
@media (max-width: 500px) {
	.rectangle.large {
		width: 40%;
		margin: 10px 0 10px 6%;
	}
}
.rectangle.large:nth-child(n + 5) {
	display: none;
}
.rectangle .wrapper {
	position: relative;
	padding: 0 10px;
	background: #474747;
}
.rectangle .wrapper .content {
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
.rectangle.large .wrapper {
	padding-bottom: 100%;
}
.rectangle .wrapper .content .name-text.large {
	font-size: 20px;
}
.rectangle .wrapper .content .name-text {
	color: #eee;
	font-weight: bolder;
}
.rectangle .wrapper .content .odd-text.large {
	font-size: 15px;
	margin-top: -15px;
	display: none;
}
.rectangle .wrapper .content .odd-text {
	text-align: center;
	color: #ddb285;
	font-weight: bold;
}
.bottom-bar {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	height: 55px;
	z-index: 2;
	background: #282828;
	width: 100%;
	max-width: 600px;
}
.bottom-bar .bar .left,
.bottom-bar .bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.bottom-bar .bar {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	right: 0;
	height: 55px;
	background: #000;
	z-index: 2;
	color: #eee;
}
.bottom-bar .bar .left,
.bottom-bar .bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.bottom-bar .bar .left .item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50px;
	font-size: 13px;
}
.bottom-bar .bar .left .item .text {
	font-size: 14px;
	color: #eee;
}
.jixuanico {
	font-size: 20px;
}
.bottom-bar .bar .left .line {
	width: 2px;
	height: 40px;
	background: #dadada;
}
.bottom-bar .bar .mid {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.bottom-bar .bar .mid .text {
	font-size: 15px;
	font-weight: 500;
	color: #eee;
}
.bottom-bar .bar .mid .text.num {
	margin: 0 5px;
	color: #ddb285;
	font-weight: bold;
}
.bottom-bar .bar .right {
	padding: 0 10px;
	margin: 0 10px;
	color: #8a5623;
	background: linear-gradient(#fde3ca, #e7b486);
	font-size: 16px;
	font-weight: 500;
	height: 35px;
	line-height: 35px;
	border-radius: 20px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 15px;
}
::v-deep .van-popup {
	position: absolute;
	z-index: 2;
	background: #282828;
}
::v-deep .van-overlay {
	position: absolute;
	background-color: rgb(70 67 67 / 70%);
}
::v-deep .van-popup--top {
	top: -1px;
	max-height: 295px;
}
.wrapper {
	overflow: auto;
}
.wrapper .item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 0;
}
.wrapper .item:first-child > div {
	color: #bb752b;
	font-size: 16px;
}
.wrapper .item .left {
	width: 40%;
	font-size: 15px;
	text-align: center;
	font-weight: 500;
	color: #bbb;
}
.font-weight {
	font-weight: 700 !important;
}
.wrapper .item .right {
	flex: 1;
	display: flex;
	font-size: 15px;
	justify-content: center;
	overflow: hidden;
	color: #fff;
}
.wrapper .item .kuaisan-ball .left {
	justify-content: flex-start;
}
.wrapper .item .kuaisan-ball {
	margin-left: 10px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.wrapper .item .kuaisan-ball .res-img {
	width: 25px;
	height: 25px;
	margin-right: 7px;
}
.wrapper .item .kuaisan-ball .res-des {
	font-weight: 700;
	text-align: center;
	color: #fff;
}
.wrapper .item .kuaisan-ball .res-des.middle {
	width: 100px;
	font-size: 15px;
}
.play-type-tip {
	position: unset;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 50%;
	height: 700px;
	max-height: 50%;
	z-index: 10;
	border-radius: 30px;
	overflow: hidden;
	background: #282828;
	color: #000;
}
.play-type-tip .title {
	line-height: 60px;
	background: #212121;
	text-align: center;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
}
.mask {
	background-color: rgb(0 0 0 / 0%);
	animation-duration: 0.35s;
}
.play-type-tip .wrapper {
	background-color: transparent;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.play-type-tip .wrapper .item {
	padding: 20px 20px;
	display: flex;
	align-items: flex-start;
}
.play-type-tip .wrapper .item .van-icon {
	color: #ddb285;
	font-size: 20px;
}
.play-type-tip .wrapper .item .content .content-title {
	margin-top: 22px;
	font-size: 15px;
	font-weight: 500;
	color: #000;
	line-height: 0px;
}
.play-type-tip .wrapper .item .content .content-detail {
	margin-top: 5px;
	font-size: 22px;
	color: #000;
	line-height: 30px;
}
.play-type-tip .wrapper .item .content {
	flex: 1;
	margin-left: 30px;
}
.rectangle.active .wrapper {
	background-color: #ff0404 !important;
}
.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
	color: #fff !important;
}
.bottom-bar .wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 0px 15px;
	height: 135px;
	background: #000;
	z-index: 1;
	transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);
	box-shadow: 0 0 10px 0 #cacaca;
}
.bottom-bar .wrapper.active {
	transform: translateY(-100%);
}
.bottom-bar .wrapper .item {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 45px;
	padding: 0;
}
.bottom-bar .wrapper .item .label {
	font-size: 16px;
	line-height: 30px;
	color: #ccc;
}
.bottom-bar .wrapper .item .bet-number {
	flex: 1;
	margin: 0 16px;
	overflow: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	color: #ff0404;
	font-size: 16px;
	font-weight: 500;
	height: 25px;
	line-height: 25px;
}
.bottom-bar .wrapper .item .amount-wrapper {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.van-cell {
	font-size: 16px;
	line-height: 50px;
	background: transparent;
}
.bottom-bar .wrapper .item .part {
	margin-right: 20px;
}
.bottom-bar .wrapper .item .part span {
	font-size: 16px;
	vertical-align: center;
	color: #ccc;
}
.bottom-bar .wrapper .item .part .number {
	margin: 0 5px;
	color: #ff0404;
	font-weight: 500;
}
::v-deep .van-field__control {
	color: #ff0404;
}
.confirm-order-modal {
	position: unset;
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 0 15px 15px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 90%;
	max-width: 450px;
	min-width: 300px;
	max-height: 50%;
	z-index: 10;
	background: #282828;
	border-radius: 30px;
}
.confirm-order-modal .head {
	position: relative;
	height: 40px;
}
.confirm-order-modal .head .text {
	padding: 0 20px;
	height: 30px;
	line-height: 10px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	color: #ddb285;
}
::v-deep .confirm-order-modal .van-hairline--bottom::after {
	border-bottom-width: 2px;
}
.van-popup--center {
	border-radius: 30px;
}
.confirm-order-modal .list {
	flex: 1;
	padding: 0 10px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.confirm-order-modal .list .lise-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
}
.confirm-order-modal .list .lise-item .main {
	flex: 1;
	overflow: hidden;
}
.confirm-order-modal .list .lise-item .main .bet-name {
	color: #ddb285;
	font-size: 16px;
	font-weight: 500;
	line-height: 0px;
}
.confirm-order-modal .list .lise-item .main .detail-text {
	line-height: 0px;
	font-size: 13px;
	color: #979799;
}
.confirm-order-modal .list .lise-item {
	color: #ddb285;
}
.confirm-order-modal .sub-bar {
	display: flex;
	align-items: center;
	margin-top: 30px;
	justify-content: space-around;
}
.confirm-order-modal .sub-bar .item {
	min-width: 40%;
	height: 40px;
	text-align: center;
	box-sizing: border-box;
	border-radius: 20px;
	font-size: 15px;
	font-weight: 500;
}
.confirm-order-modal .sub-bar .item.cancel-btn {
	border: 2px solid #979799;
	color: #979799;
	background: #282828;
}
.confirm-order-modal .sub-bar .item.sub-btn {
	background: linear-gradient(#fde3ca, #e7b486);
	color: #8a5623;
	border: 0;
}
.next-number span {
	font-size: 19px;
	font-weight: 700;
	color: #000;
	float: right;
}
::v-deep .van-icon {
	color: #fff;
}
</style>
