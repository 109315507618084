<template>
	<div class="container page">
		<van-nav-bar :title="this.videoInfo.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#ddb285" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="movie-video">
			<video id="my-video" class="video-js"></video>
		</div>
		<div class="movie-content">
			<div class="movie-descript">
				<p>{{ this.videoInfo.vod_name }}</p>
				<span>{{ this.videoInfo.count }} {{ $t("views") }}</span>
			</div>

			<div class="movie-body">
				<div class="movie-title">
					<div>
						<span>{{ $t("recommended") }}</span>
					</div>
				</div>
				<div class="movie-list">
					<div class="movie-play-item" @click="toPlayVideo(v.id)" v-for="(v, key) in moreVideoInfo" :key="key">
						<div>
							<img :src="v.vod_pic" />
							<div>
								<div class="van-count-down">{{ v.time }}</div>
							</div>
						</div>
						<div>
							<p>{{ v.vod_name }}</p>
							<span>{{ v.count }} {{ $t("views") }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
	data() {
		return {
			nowPlayVideoUrl: "",
			cover: "",
			userInfo: [],
			videoInfo: {},
			moreVideoInfo: {},
			player: null,
		};
	},
	methods: {
		back() {
			this.$router.push({path: "Home"});
		},
		getVideoInfo() {
			this.$http({
				method: "get",
				data: {id: this.$route.query.id},
				url: "video_get_info",
			}).then(res => {
				this.videoInfo = res.data;
				console.log(this.videoInfo.vod_play_url);
				this.nowPlayVideoUrl = this.videoInfo.vod_play_url;
				this.cover = this.videoInfo.vod_pic;
				let videos = document.getElementById("my-video");
				videos.poster = this.cover;
				if (this.videoInfo.vod_play_url.indexOf(".m3u8") != -1) {
					this.getVideo();
				} else if (this.videoInfo.vod_play_url.indexOf(".mp4") != -1) {
					videos.setAttribute("src", this.videoInfo.vod_play_url);
					videos.setAttribute("type", "video/mp4");
				} else {
					videos.remove();
					const node = document.createElement("iframe");
					node.setAttribute("src", this.videoInfo.vod_play_url);
					document.querySelector(".movie-video").appendChild(node);
				}
			});
		},
		toPlayVideo(id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "?id=" + id});
				location.reload();
			}
		},
		getMoreVideoItem() {
			this.$http({
				method: "get",
				url: "video_get_more_item",
			}).then(res => {
				this.moreVideoInfo = res.data;
			});
		},
		getVideo() {
			this.player = videojs("my-video", {
				height: "200px",
				preload: "auto",
				controls: true,
				multipleArray: [0.75, 1, 1.5, 2],
			});
			this.player.src([
				{
					src: this.nowPlayVideoUrl,
					type: "application/x-mpegURL",
				},
			]);
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					if (this.userInfo.status !== 1) {
						this.$toast(this.$t("offline"));
						localStorage.clear();
						this.$router.push({path: "/Login"});
					} else {
						if (this.$store.getters.getBaseInfo.isplay == 1) {
							this.getVideoInfo();
							this.getMoreVideoItem();
							if (this.userInfo.money <= "0.00") {
								var a = this;
								setTimeout(function() {
									a.$toast(this.$t("pls_vip"));
									a.$router.push({path: "/Home"});
								}, 10000);
							}
						} else {
							this.getVideoInfo();
							this.getMoreVideoItem();
						}
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({path: "/Login"});
		} else {
			this.getUserInfo();
		}
	},
	destroyed() {
		this.player.dispose();
	},
};
</script>

<style scoped>
.video-js {
	width: 100%;

	font-size: 24px;
}
.movie-content {
	flex: 1;
	overflow-y: auto;
}
.movie-content .movie-descript {
	width: 100%;
	height: 100px;
	background: #282828;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding: 15px;
}
.movie-content .movie-descript p {
	font-size: 16px;
	font-weight: 700;
	color: #ddb285;
}
.movie-content .movie-descript span {
	color: #eee;
	font-size: 14.5px;
}
.movie-content .movie-body {
	width: calc(100% - 20px);
	margin: 0 auto;
}
::v-deep .movie-video .video-js .vjs-big-play-button {
	top: 50%;
	left: 50%;
	margin: 0;
	transform: translate(-50%, -50%);
	font-size: 1.5em;
	padding: 0.3em;
	width: 2em;
	height: 1.3em;
	line-height: 1.2;
	::before {
		font-size: 1em;
	}
}
::v-deep .movie-video iframe {
	width: 100%;
	min-height: 200px;
	height: 15vw;
	border: 0;
}
.movie-content .movie-body .movie-title {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.movie-content .movie-body .movie-title > div:first-child {
	width: 200px;
}
.movie-content .movie-body .movie-title > div:first-child span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 15px;
	font-weight: 700;
	color: #ddb285;
}
.movie-content .movie-body .movie-title > div:first-child span:before {
	content: "";
	display: block;
	width: 5px;
	height: 22px;
	background-color: #ddb285;
	border-radius: 15px;
	margin-right: 10px;
}
.movie-play-item {
	width: 100%;
	border-radius: 10px;
	position: relative;
	display: flex;
	background: #282828;
	margin-bottom: 20px;
}
.movie-play-item > div {
	height: 100%;
}
.movie-play-item > div:first-child {
	position: relative;
	width: 30%;
}
.movie-play-item > div:nth-child(2) {
	flex: 1;
}
.movie-play-item > div:first-child > img {
	width: 100%;
	height: 100%;
	border-radius: 10px 0 0 10px;
	aspect-ratio: 1;
	object-fit: cover;
}
.movie-play-item > div:first-child > div {
	position: absolute;
	width: 100%;
	height: 22px;
	bottom: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 0 0 0 10px;
}
.movie-play-item > div:first-child > div .van-count-down {
	color: #fff;
	font-size: 13px;
}
.movie-play-item > div:nth-child(2) p {
	height: 60px;
	font-size: 14px;
	line-height: 1.3;
	overflow: hidden;
	color: #ddb285;
}
.movie-play-item > div:nth-child(2) span {
	color: #eee;
	font-size: 13px;
}
.movie-play-item > div:nth-child(2) {
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	box-sizing: border-box;
}
</style>
