<template>
	<div class="home-container">
		<div data-v-28ff3c6a="" class="linear-bg">
			<div data-v-28ff3c6a="" style="display:flex;justify-content:space-between;align-items:center;width:100%;padding:0px 10px;font-size:13px">
				<div @click="$router.push({path: 'ServiceOnline'})" data-v-28ff3c6a="" style="display:flex;align-items:center;gap:5px"><img src="/img/mine/i2.png" style="height:22px" />{{ $t("service") }}</div>
				<div @click="$router.push({path: 'Language'})" data-v-28ff3c6a="" style="display:flex;align-items:center;gap:5px">
					<svg data-v-355cc69a="" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e6bc93" fill="none" stroke-linecap="round" stroke-linejoin="round">
						<path data-v-355cc69a="" stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path data-v-355cc69a="" d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
						<path data-v-355cc69a="" d="M3.6 9h16.8"></path>
						<path data-v-355cc69a="" d="M3.6 15h16.8"></path>
						<path data-v-355cc69a="" d="M11.5 3a17 17 0 0 0 0 18"></path>
						<path data-v-355cc69a="" d="M12.5 3a17 17 0 0 1 0 18"></path>
					</svg>
					{{ $t("language") }}
				</div>
			</div>
		</div>
		<div class="home-scroll">
			<div class="banner">
				<swiper class="banner_swiper" :options="bannerSwiperOption">
					<swiper-slide v-for="(v, key) in banners" :key="key">
						<van-image class="banner_img" round :src="v.url">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
					</swiper-slide>
				</swiper>
			</div>
			<div class="notice-bar">
				<van-notice-bar class="notice-swipe" left-icon="bullhorn-o" :text="$t('home_notice')" />
			</div>
			<!--<div class="card" @click="$router.push({path: '/Choose'})">
				<div class="sm" style="flex-direction:row">
					<div style="width:25%;padding-right:10px;border-right:1px solid #f6d5a1">
						<div class="text-big">{{ $t("heaven") }}</div>
					</div>
					<div style="width:75%;padding-left:10px;font-size:12px;color:#ddb285">
						{{ $t("text7") }}
					</div>
				</div>
			</div>-->
			<div class="hot-game">
				<div class="hot-title-div">
					<div>
						<span>{{ $t("home_title1") }}</span>
					</div>
					<div @click="gotoMenu('/Game')">
						<span>{{ $t("more") }}</span>
						<van-icon name="arrow" color="#979799" />
					</div>
				</div>
				<div class="hot-items-div">
					<van-grid :border="false" :column-num="4" :icon-size="20">
						<van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
							<van-image class="game_item_img" :src="v.ico">
								<template v-slot:loading>
									<van-loading type="circular" />
								</template>
							</van-image>
							<span>{{ $t("voting") }} {{ v.name }}</span>
						</van-grid-item>
					</van-grid>
				</div>

				<div class="linear-gradient"></div>
				<!--<div class="hot-title-div">
					<div>
						<span>{{ $t("home_title2") }}</span>
					</div>
					<div @click="gotoMenu('/Game')">
						<span>{{ $t("more") }}</span>
						<van-icon name="arrow" color="#979799" />
					</div>
					</div>
					<div class="hot-items-div">
					<van-grid :border="false" :column-num="4" :icon-size="20">
						<van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem2" :key="key">
						<van-image class="game_item_img" :src="v.ico">
							<template v-slot:loading>
							<van-loading type="circular" />
							</template>
						</van-image>
						<span>{{$t('voting')}} {{ v.name }}</span>
						</van-grid-item>
					</van-grid>
					</div>-->
			</div>
			<van-pull-refresh border="false" class="list-wrapper" v-model="isLoading">
				<van-grid :column-num="1">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
						<div class="card">
							<div class="sm">
								<div class="girl-left">
									<div class="girl-name">{{ v.xuanfei_name }}</div>
									<div class="flex">
										<img src="/img/mine/i9.png" /> <span>{{ $t("type") }}: {{ v.type }}</span>
									</div>
									<div class="flex">
										<img src="/img/mine/i8.png" /> <span>{{ $t("about") }}: {{ v.info }}</span>
									</div>
									<div class="flex">
										<img src="/img/mine/i10.png" /> <span>{{ $t("address") }}: {{ v.address }}</span>
									</div>
								</div>
								<div class="girl-right">
									<van-image class="game_item_img" :src="v.img_url">
										<template v-slot:loading>
											<van-loading type="circular" />
										</template>
									</van-image>
									<i>{{ $t("verified") }}</i>
								</div>
							</div>
						</div>
					</van-grid-item>
				</van-grid>
			</van-pull-refresh>
			<van-popup class="mask popup-z" get-container="body" v-model="popup">
				<div class="title-popup">{{ this.popupUser.name }}</div>
				<div class="x-notify-separator"></div>
				<div class="content-popup">
					{{ this.popupUser.text }}
				</div>
				<div class="footer-popup">
					<button @click="closePopup()">{{ $t("close") }}</button>
				</div>
			</van-popup>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			notice: "Nhận......",
			noticeUser: {},
			popupUser: {},
			popup: localStorage.getItem("popup") == "true" ? true : false,
			banners: [{}],
			basicData: [],
			datalist: [{}],
			gameitem: [{}, {}, {}, {}],
			gameitem2: [{}, {}, {}, {}],
			movielist_0: [{}, {}, {}, {}],
			movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
			isLoading: false,
			movielistSwiperOption: {
				slidesPerView: "auto",
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
			bannerSwiperOption: {
				effect: "coverflow",
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: "auto",
				speed: 800,
				autoplay: true,
				coverflowEffect: {
					rotate: 50,
					stretch: 10,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				},
			},
		};
	},
	methods: {
		gotoMenu(router) {
			this.$router.replace(router);
		},
		toLottery(key, id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/Lottery?key=" + key + "&id=" + id});
			}
		},
		toPlayVideo(id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/PlayVideo?id=" + id});
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.getBasicConfig();
				this.isLoading = false;
				this.$toast(this.$t("renew"));
			}, 500);
		},
		getBasicConfig() {
			this.$http({
				method: "get",
				url: "sys_config",
			}).then(res => {
				this.basicData = res.data;
				this.getNotice(this.basicData);
				this.getBanner(this.basicData);
				this.getxuanfeilist();
				this.getGameItem();
				this.getGameItem2();
				//this.getMovieList_0(this.basicData);
				//this.getMovieList_1(this.basicData);
			});
		},
		getxuanfeilist() {
			this.$http({
				method: "get",
				url: "xuanfeilist",
				data: {id: this.$route.query.id},
			}).then(res => {
				this.datalist = res.data;
			});
		},
		getNotice(data) {
			this.notice = data.notice;
		},
		getNoticeList() {
			this.$http({
				method: "get",
				url: "sys_get_notice",
			}).then(res => {
				if (res.data.length > 0) {
					if (localStorage.getItem("popup") != res.data[0].name) {
						this.popup = true;
						this.popupUser = res.data[0];
					}
				}
			});
		},
		closePopup() {
			localStorage.setItem("popup", this.popupUser.name);
			this.popup = false;
		},
		getGameItem() {
			this.$http({
				method: "get",
				url: "lottery_hot",
			}).then(res => {
				this.gameitem = res.data;
			});
		},
		getGameItem2() {
			this.$http({
				method: "get",
				url: "lottery_hot2",
			}).then(res => {
				this.gameitem2 = res.data;
			});
		},
		getMovieList_0(data) {
			this.movielist_0 = data.movielist_0;
		},
		getMovieList_1(data) {
			this.movielist_1 = data.movielist_1;
		},
		getBanner(data) {
			this.banners = data.banners;
		},
		profile(id) {
			this.$router.push({path: "/profile?id=" + id});
		},
	},
	mounted() {},
	created() {
		this.getBasicConfig();
		this.getNoticeList();
	},
};
</script>

<style lang="less" scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;
.linear-bg {
	display: flex;
	height: 40px;
	background: #16151b;
	color: #f3c7a2;
	text-align: center;
	line-height: 1.2;
	width: 100%;
	justify-content: center;
}
.linear-gradient {
	width: 100%;
	height: 2px;
	background: linear-gradient(to right, rgba(126, 86, 120, 0), #ddb285, rgba(126, 86, 120, 0));
	margin: 20px 0 10px;
}
::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
	min-width: 30px;
}
::v-deep .van-notice-bar {
	background: transparent;
	color: #ddb285;
	border: 1px solid #7e6a56;
	border-radius: 5px;
}
.notice-bar {
	padding: 10px;
}
.notice-swipe {
	width: 100%;
	height: 45px;
	margin-top: 15px;
	font-size: 16px;
}
::v-deep .van-icon-bullhorn-o::before {
	transform: scale(1.5);
}
.banner {
	width: 100%;
	margin-top: 4%;
}
.banner_swiper {
	height: 100%;
	width: 100%;

	.swiper-slide {
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80%;
		height: auto;
		aspect-ratio: 1.75;
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		background-color: #ffffff;
		background-position: center;
		background-size: cover;
		color: #ffffff;
	}
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img {
	border-radius: 10px;
	width: 100%;
	height: 100%;
}
.hot-game {
	width: 100%;
	height: 100%;
	background: #282828;
}
.hot-title-div {
	width: calc(100% - 20px);
	margin: 0 auto;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.hot-title-div div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: calc(100% - 80px);
}
.hot-title-div > div:first-child span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.85rem;
	font-weight: 700;
	color: #ddb285;
	white-space: nowrap;
}
@media (min-width: 550px) {
	.hot-title-div > div:first-child span {
		font-size: 1rem;
	}
}
.hot-title-div > div:nth-child(2) {
	width: 75px;
}
.hot-title-div > div:nth-child(2) span {
	font-size: 12px;
	color: #979799;
}
.hot-title-div > div:first-child span:before {
	content: "";
	display: block;
	width: 5px;
	height: 22px;
	background-color: #ddb285;
	border-radius: 1px;
	margin-right: 5px;
}
.hot-game .hot-items-div {
	margin-top: -3px;
}
.hot-game .hot-items-div span {
	margin-top: 10px;
	font-size: 14px;
	color: #eee;
}
.hot-recommend {
	width: 100%;
	flex: 1;
	background: #282828;
}
.movie_swiper {
	.swiper-slide {
		width: 80%;
	}
}
.movie_list_0 {
	width: calc(100% - 20px);
	margin: 0 auto;
}
.movie_cover {
	border-radius: 10px;
	width: 90%;
	height: auto;
	padding-bottom: 50%;
}
.movie_list_0 .movie-list-item-bottom {
	position: relative;
	width: 90%;
	bottom: 38px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div {
	background-color: rgba(0, 0, 0, 0.4);
	padding: 7px 0;
}
.movie_list_0 .movie-list-item-bottom > div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
	font-size: 14px;
	color: #fff;
	padding-right: 3px;
}
.movie_list_0 .movie-time-div {
	color: #fff;
	border-radius: 0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 45px;
}
.movie_list_0 span {
	font-size: 16px;
}
.movie_list_1 {
	display: grid;
	width: calc(100% - 20px);
	margin: 0 auto;
	grid-template-columns: 1fr 1fr;
	grid-gap: 5px 8px;
}
.movie_list_1 .movie-list-item .cover_img {
	border-radius: 10px;
	width: 100%;
	position: relative;
	padding-bottom: 85%;
}
.home-scroll {
	padding-bottom: 85px;
}
.movie_list_1 .movie-list-item {
	position: relative;
}
.movie_cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
}
.movie_list_1 .movie-list-item-bottom {
	position: absolute;
	width: 100%;
	bottom: 5px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div {
	background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_1 .movie-list-item-bottom > div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
	color: #fff;
}
.movie_list_1 .movie-time-div {
	color: #fff;
	border-radius: 0 0 10px 10px;
	height: 35px;
}
.movie_list_1 .movie_swiper .hot {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
	overflow: hidden;
	white-space: nowrap;
	width: 60%;
	padding-left: 8px;
	font-size: 14px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
	overflow: hidden;
	white-space: nowrap;
	padding-right: 3px;
	font-size: 14px;
}
.movie_list_0 .movie-time-div span:first-child {
	overflow: hidden;
	white-space: nowrap;
	width: 60%;
	padding-left: 10px;
	font-size: 14px;
}

.hot-recommend-more {
	width: 100%;
	padding-bottom: 20px;
	text-align: center;
	color: #979799;
	font-size: 15px;
	margin-top: 10px;
}
.hot-items-div .game_item_img {
	width: 60px;
	height: 60px;
}
::v-deep .hot-items-div .game_item_img .van-image__img {
	border-radius: 0;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 16px;
}
::v-deep .van-grid {
	align-items: center;
}
::v-deep .van-grid > .van-grid-item {
	flex: 1;
}
.popup-z {
	border-radius: 10px;
	padding: 20px;
	width: 90%;
	max-width: 500px;
}
.title-popup {
	font-size: 19px;
	font-weight: 700;
	margin-bottom: 10px;
	color: #0abb87;
}
.x-notify-separator {
	width: 100%;
	height: 2px;
	background: linear-gradient(-45deg, #8fd5ef, #f5e199, #eca6d6, #808dc0, #afebe5);
	background-size: 400% 400%;
	position: relative;
	animation: change 5s ease-in-out infinite;
}
@keyframes change {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}
.content-popup {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
}
.footer-popup button {
	color: #fff;
	background-color: #0abb87;
	border: 0;
	border-radius: 5px;
	padding: 10px 20px;
	margin: 0 auto;
	display: block;
}
::v-deep .van-grid-item__content::after,
::v-deep .van-hairline--top::after {
	display: none;
}
.card {
	background: linear-gradient(120deg, #212121, #313131);
	width: 95%;
	color: white;
	font-size: 15px;
	margin: 5px auto 10px auto;
	border-radius: 10px;
	line-height: 1.3;
}
.sm {
	width: 100%;
	border-radius: 10px;
	border: 1px solid #766350;
	margin: 10px 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
	position: relative;
	overflow: hidden;
}
.sm:after,
.sm:before {
	content: "";
	position: absolute;
	width: 137.4vw;
	height: 19.2vw;
	background: linear-gradient(0deg, rgba(74, 70, 70, 0.29), transparent);
	transform: rotate(139deg);
}
.sm:before {
	left: -4%;
}
.sm:after {
	left: -35%;
	z-index: 0;
}
.van-grid-item .card .sm {
	margin: 0;
	display: flex;
	flex-direction: row;
}
.van-grid-item .card .sm .girl-left {
	width: 70%;
	line-height: 1.4;
}
.van-grid-item .card .sm .girl-right {
	width: 30%;
	position: relative;
}
.girl-right .game_item_img {
	overflow: hidden;
	border-radius: 100%;
	aspect-ratio: 1;
	width: 100%;
}
.van-grid-item .card .sm .girl-right i {
	position: absolute;
	right: -45px;
	top: 5px;
	width: 120px;
	background: #ff6056;
	font-size: 11px;
	padding: 3px 10px;
	text-align: center;
	font-style: normal;
	transform: rotate(45deg);
	color: #e7e7e7;
	z-index: 1;
}
.girl-name {
	background: linear-gradient(90deg, #efcba7, #dcab76);
	color: #8d5825;
	display: inline-block;
	padding: 3px 8px;
	border-radius: 5px;
	margin-bottom: 10px;
}
.flex {
	display: flex;
	align-items: center;
	gap: 5px;
}
.flex img {
	height: 18px;
}
::v-deep .van-grid-item__content--center {
	padding: 15px;
	height: auto;
	background: none;
}
::v-deep .van-grid-item__content--center {
	padding: 0;
	height: auto;
	background: none;
}
.text-big {
	font-weight: bold;
	background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
</style>
