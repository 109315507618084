<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('lobby')" />
		<div class="convention-item">
			<div class="item_" @click="$router.push({path: '/Video'})">
				<div class="left">
					<img src="/img/lottery/ic-video.png" /><span>{{ $t("cinema") }}</span>
				</div>
				<div class="right">
					<van-icon name="arrow" />
				</div>
			</div>
      <div class="item_" @click="$router.push({path: '/Choose'})">
				<div class="left">
					<img src="/img/lottery/ic-heart.png" /><span>{{ $t("heaven") }}</span>
				</div>
				<div class="right">
					<van-icon name="arrow" />
				</div>
			</div>
      <br/>
			<div class="bg-title">{{ $t("lobby") }}</div>
			<div class="right">
				<van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading">
					<van-grid :column-num="2">
						<van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
							<van-image class="game_item_img" :src="v.ico">
								<template v-slot:loading>
									<van-loading type="circular" />
								</template>
							</van-image>
							<span>{{ $t('voting') }} {{ v.name }}</span>
							<!--<span>{{ v.desc }}</span>-->
						</van-grid-item>
					</van-grid>
				</van-pull-refresh>
			</div>
		</div>
	</div>
</template>

<script>
import {Toast} from "vant";
export default {
	data() {
		return {
			gameitem: [{}, {}, {}, {}],
			lotteryitem: [{}, {}, {}, {}],
			isLoading: false,
			activeKey: 0,
		};
	},
	methods: {
		onRefresh() {
			setTimeout(() => {
				Toast(this.t("renew"));
				this.isLoading = false;
			}, 500);
		},
		toLottery(key, id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/Lottery?key=" + key + "&id=" + id});
			}
		},
		getGameItem() {
			this.$http({
				method: "get",
				url: "lottery_list",
			}).then(res => {
				this.gameitem = res.data;
			});
		},
		onChange(index) {
			this.$http({
				method: "get",
				data: {class: index},
				url: "lottery_list",
			}).then(res => {
				this.gameitem = res.data;
			});
		},
		getLotteryItem() {
			this.$http({
				method: "get",
				url: "lottery_class",
			}).then(res => {
				this.lotteryitem = res.data;
			});
		},
	},
	created() {
		this.getGameItem();
		this.getLotteryItem();
	},
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background: #282828;
}
.nav-bar {
	background: #212121;
	height: 50px;
}
.van-nav-bar {
	line-height: 50px;
}

::v-deep .van-nav-bar__title {
	max-width: 80%;
	margin: 0 auto;
	color: #ddb285;
	font-size: 18px;
}
::v-deep .van-nav-bar__content {
	height: 50px;
}
.van-sidebar {
	width: 100%;
}
.van-sidebar-item--select::before {
	left: 3px;
	height: 55%;
	background-color: #ddb285;
	border-radius: 5px;
	width: 4px;
}

.van-sidebar-item--select {
	color: #ddb285;
	font-size: 15px;
	text-align: center;
}
.van-sidebar-item {
	font-size: 15px;
	text-align: center;
	padding: 20px 10px;
	width: 100%;
}
.van-sidebar-item--select,
.van-sidebar-item--select:active {
	background: #282828;
}
.convention-item {
  background: #282828;
  padding-bottom: 30px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #282828;
}
.convention-item .right .list-wrapper {
	padding: 0 10px;
}
.convention-item .right .list-wrapper .game_item_img {
	width: 100%;
	height: auto;
}
.convention-item .right .list-wrapper span {
	margin-top: 10px;
	font-size: 15.5px;
	color: #eee;
}
.convention-item .right .list-wrapper span:last-child {
	margin-top: 10px;
	font-size: 13.5px;
	color: #eee;
}
::v-deep .van-grid-item__content--center {
	border-radius: 10px;
	background: transparent;
}
::v-deep .van-image__img {
	border-radius: 0;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 16px;
}
::v-deep .van-grid-item__content::after {
	display: none;
}
.bg-title {
	background-image: url(/img/lottery/bg-title.png);
	background-size: 100%;
	background-repeat: no-repeat;
	height: 32px;
	width: 136px;
	margin: 15px 15px 0;
	color: #8a5623;
	padding-left: 35px;
	padding-top: 6px;
	font-size: 14px;
	font-weight: bold;
}
::v-deep .van-hairline--top::after {
	display: none;
}
.item_ {
	padding: 15px;
	background: #282828;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	color: #fff;
}
.item_ .left {
	display: flex;
	align-items: center;
	gap: 5px;
}
.item_ .right {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.item_ .left img {
	width: 25px;
}
.item_ .right img {
	width: 20px;
	margin-right: 10px;
}
.item_ .right .van-icon {
	color: #969799;
	font-size: 20px;
}
</style>
