<template>
	<div class="bg-container page">
		<img class="bg-img" src="img/login/bg-login.webp" />
		<div class="bg-wrapper">
			<div class="register">
				<van-nav-bar class="nav-bar">
					<template #left>
						<van-icon name="arrow-left" color="#000" @click="back()" />
					</template>
					<template #right>
						<svg @click="$router.push({path: 'Language'})" xmlns="http://www.w3.org/2000/svg" class="icon-language" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
							<path d="M3.6 9h16.8" />
							<path d="M3.6 15h16.8" />
							<path d="M11.5 3a17 17 0 0 0 0 18" />
							<path d="M12.5 3a17 17 0 0 1 0 18" />
						</svg>
					</template>
				</van-nav-bar>
				<div class="wrapper">
					<div class="logo-container">
						<div class="logo-wrapper">
							<img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !== undefined ? this.$store.getters.getBaseInfo.ico : ''" />
						</div>
					</div>
					<!--<h3 class="big-title">NƠI DỪNG CHÂN THĂNG HOA</h3>-->
					<div class="title">{{ $t("register") }}</div>
					<div class="loginForm">
						<van-field v-model="username" clearable input-align="center" class="input" :placeholder="$t('username')" />
						<van-field v-model="password" :type="passwordType" input-align="center" class="input" :placeholder="$t('password')">
							<template slot="right-icon">
								<van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
							</template>
						</van-field>
						<!--<van-field
                v-model="repassword"
                type="password"
                input-align="center"
                class="input"
                :placeholder="$t('new_pwd2')">
              <template slot="right-icon">
                <van-icon :name=" passwordType2 === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType2"/>
              </template>
            </van-field>
            <van-field
                v-model="pass2"
                :type="passwordType2"
                input-align="center"
                class="input"
                :placeholder="$t('paypwd')">
              <template slot="right-icon">
                <van-icon :name=" passwordType2 === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType2"/>
              </template>
            </van-field>-->
						<van-field v-model="code" clearable input-align="center" class="input" :placeholder="$t('code')" />
						<div class="agreement">
							<van-checkbox v-model="checked" />
							<span class="agreement-text">{{ $t("agree") }}</span>
						</div>
						<van-button class="login-btn" type="primary" size="normal" @click="doRegister()">{{ $t("register") }}</van-button>
						<div style="display:flex;justify-content: space-between;font-size:16px;align-items: center;margin-top:20px;color:#fff">
							<div style="line-height:1.5">
								<span @click="$router.push({path: '/Login'})" style="cursor: pointer;">{{ $t("login") }}</span>
							</div>
							<div style="font-size:16px;text-align:right;margin-right:10px">
								<a :href="this.$store.getters.getBaseInfo.kefu" target="_blank" style="color:#fff"><img src="/img/mine/service.png" style="width:20px;position:relative;top:5px" /> {{ $t("service") }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: "inputValue",
		event: "input",
	},
	props: {
		inputValue: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			checked: true,
			username: "",
			code: "",
			password: this.inputValue,
			repassword: this.inputValue,
			pass2: this.inputValue,
			passwordType: "password",
			passwordType2: "password",
		};
	},
	methods: {
		switchPasswordType() {
			this.passwordType = this.passwordType === "password" ? "text" : "password";
		},
		switchPasswordType2() {
			this.passwordType2 = this.passwordType2 === "password" ? "text" : "password";
		},
		back() {
			return window.history.back();
		},
		doRegister() {
			if (this.username === "" || this.username === null || this.username === undefined) {
				this.$toast.fail(this.$t("pls_username"));
				return false;
			}
			if (this.password === "" || this.password === null || this.password === undefined) {
				this.$toast.fail(this.$t("pls_password"));
				return false;
			}
			/*if(this.pass2 === "" || this.pass2 === null || this.pass2 === undefined){
        this.$toast.fail(this.$t('pls_paypwd'));
        return false;
      }
      if (this.password != this.repassword) {
        this.$toast.fail(this.$t('pwd_notmatch'));
        return false;
      }*/
			if (this.code === "" || this.code === null || this.code === undefined) {
				this.$toast.fail(this.$t("pls_code"));
				return false;
			}
			if (!this.checked) {
				this.$toast.fail(this.$t("pls_agree"));
				return false;
			}
			this.$http({
				method: "post",
				data: {
					username: this.username,
					password: this.password,
					pass2: this.pass2,
					code: this.code,
				},
				url: "member_register",
			}).then(res => {
				if (res.code === 200) {
					this.$toast.success(this.$t("regis_success"));
					localStorage.setItem("token", res.data);
					this.$router.push("Mine");
				} else {
					this.$toast.fail(res.msg);
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("token")) {
			return window.history.back();
		}
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.bg-container .bg-wrapper {
	background: linear-gradient(hsla(0, 0%, 100%, 0), #876037);
}
.bg-container {
	background: none;
}
.register {
	height: 100vh;
	overflow: hidden;
}
.bg-container .bg-wrapper .register .nav-bar {
	background: 0 0;
}
.register .wrapper {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.logo-container {
	margin: 0 auto;
	width: 45%;
	display: none;
}
.logo-container .logo-wrapper {
	position: relative;
	padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}
.register .wrapper .title {
	line-height: 70px;
	text-align: center;
	font-size: 25px;
	font-weight: 700;
	color: #fff;
	letter-spacing: 1px;
	margin-top: 20px;
}
.register .wrapper .loginForm {
	padding: 15px 30px;
}
.register .wrapper .loginForm .input {
	padding: 10px 10px;
	margin-top: 20px;
	border-radius: 20px;
	text-align: center;
	line-height: 30px;
	font-size: 16px;
	color: #4e4e4e;
}
::v-deep .van-field__right-icon .van-icon {
	font-size: 17px;
}
::v-deep .van-icon {
	font-size: 14px;
}
.register .wrapper .loginForm .reset-text {
	margin: 15px 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
}
.register .wrapper .loginForm .register-text {
	margin: 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.register .wrapper .loginForm .register-text span {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
}
.register .wrapper .loginForm .login-btn {
	margin-top: 5px;
	width: 100%;
	height: 40px;
	border-radius: 20px;
	color: #fff;
	background-color: #ddb285;
	font-size: 16px;
	font-weight: bolder;
	border: none;
}
.register .wrapper .loginForm .agreement {
	margin: 15px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.register .wrapper .loginForm .agreement .agreement-text {
	margin-left: 10px;
	font-size: 11px;
	color: #fff;
	font-weight: 500;
	line-height: 20px;
}
::v-deep .agreement {
	font-size: 16px;
}
::v-deep .agreement .van-checkbox__icon {
	font-size: 18px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
	color: #fff;
	border-color: #ddb285;
	background-color: #ddb285;
}
.big-title {
	color: #fff;
	text-align: center;
	margin-bottom: 30px;
	font-size: 25px;
}
</style>
