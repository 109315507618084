<template>
	<div class="mine page">
		<div class="page-bg"></div>
		<div class="wrapper">
			<van-pull-refresh v-model="isLoading">
				<div class="header">
					<div class="absolute-top">
						<div @click="toService()"><img src="/img/mine/i2.png" /></div>
						<div @click="$router.push({path: 'Language'})">
							<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e6bc93" fill="none" stroke-linecap="round" stroke-linejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
								<path d="M3.6 9h16.8" />
								<path d="M3.6 15h16.8" />
								<path d="M11.5 3a17 17 0 0 0 0 18" />
								<path d="M12.5 3a17 17 0 0 1 0 18" />
							</svg>
						</div>
					</div>
					<div class="user-wrapper" @click="doLogin()">
						<van-image round class="user_img" :src="this.userInfo.header_img">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<div class="login-content">
							<p class="login-btn">{{ this.userInfo.username }}</p>
							<p class="login-label" v-if="this.userInfo.money" style="color:#c89d60;font-weight:bold"><img :src="`/img/vip/v${this.userInfo.vip ? this.userInfo.vip : '1'}.png`" width="40" style="position:relative;top:10px;margin-right:2px" /> VIP {{ this.userInfo.vip ? this.userInfo.vip : "1" }}</p>
						</div>
					</div>
				</div>
				<div style="margin:15px 0">
					<div class="item_" @click="refresh()">
						<div class="left">
							<img src="/img/mine/i1.png" /><span>{{ $t("money") }}</span>
						</div>
						<div class="right">
							<img src="/img/mine/refresh.png" />
							<span class="desc">{{ Number(this.userInfo.money).toLocaleString() }}</span>
						</div>
					</div>
					<div class="item_" @click="refresh()">
						<div class="left">
							<img src="/img/mine/i8.png" /><span>{{ $t("credit") }}</span>
						</div>
						<div class="right">
							<img src="/img/mine/refresh.png" />
							<span class="desc">{{ this.userInfo.credit ? this.userInfo.credit : "0" }}</span>
						</div>
					</div>
				</div>
				<div class="item_" @click="$router.push({path: '/Personalreport'})">
					<div class="left">
						<img src="/img/mine/i5.png" /><span>{{ $t("account_detail") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<!--<div class="item_" @click="toService()">
					<div class="left">
						<img src="/img/mine/i2.png" /><span>{{ $t("service") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>-->
				<div class="item_" @click="doWithdrawal()">
					<div class="left">
						<img src="/img/mine/i3.png" /><span>{{ $t("withdraw") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="item_" @click="$router.push({path: '/WithdrawRecord'})">
					<div class="left">
						<img src="/img/mine/i9.png" /><span>{{ $t("history_withdraw") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="item_" @click="$router.push({path: '/RechargeRecord'})">
					<div class="left">
						<img src="/img/mine/i4.png" /><span>{{ $t("history_recharge") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="item_" @click="$router.push({path: '/GameRecord'})">
					<div class="left">
						<img src="/img/mine/i5.png" /><span>{{ $t("history_play") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="item_" @click="$router.push({path: '/Notice'})">
					<div class="left">
						<img src="/img/mine/i6.png" /><span>{{ $t("notice") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div class="item_" @click="$router.push({path: '/Setbank'})">
					<div class="left">
						<img src="/img/mine/i10.png" /><span>{{ $t("bind_bank") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<!--<div class="item_" @click="$router.push({path: 'Language'})">
					<div class="left">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e6bc93" fill="none" stroke-linecap="round" stroke-linejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
								<path d="M3.6 9h16.8" />
								<path d="M3.6 15h16.8" />
								<path d="M11.5 3a17 17 0 0 0 0 18" />
								<path d="M12.5 3a17 17 0 0 1 0 18" />
							</svg><span>{{ $t("language") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>-->
				<div class="item_" @click="showSetting()">
					<div class="left">
						<img src="/img/mine/i7.png" /><span>{{ $t("setting") }}</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
				<div style="padding:25px"></div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			userInfo: {},
			menu_top: 40,
			isLoading: false,
		};
	},
	methods: {
		refresh() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem("token")) {
					this.$toast(this.$t("renew"));
				} else {
					this.$router.push({path: "/Login"});
				}
			}, 500);
		},
		exit() {
			this.$toast(this.$t("have_binded"));
		},
		showSetting() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Setting"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		toNotice() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Notice"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem("token")) {
					this.getUserInfo();
					this.$toast(this.$t("renew"));
				} else {
					this.$router.push({path: "/Login"});
				}
			}, 500);
		},
		doLogin() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Infomation"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		doPay() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Recharge"});
				//this.$toast(this.$t('pls_contact'));
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		doWithdrawal() {
			this.$http({
				method: "get",
				url: "user_get_bank",
			}).then(res => {
				if (res.data.is_bank) {
					this.$router.push("withdraw");
				} else {
					this.$router.push("BindCard");
					//this.$toast.fail("Vui lòng thiết lập ngân hàng!");
				}
			});
		},
		toService() {
			if (this.$store.getters.getBaseInfo.iskefu == 1) {
				this.$router.push("ServiceOnline");
			} else {
				this.$toast.fail("Tính năng bị vô hiệu hóa!");
			}
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.menu_top = 15;
					if (this.userInfo.status !== 1) {
						this.$toast(this.t("offline"));
						localStorage.clear();
						this.$router.push({path: "/Login"});
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("token")) {
			this.getUserInfo();
		} else {
			this.$router.push("Login");
			this.userInfo.username = "Đăng nhập / Đăng ký";
			this.userInfo.ip = null;
			this.userInfo.header_img = "img/mine/avatar.png";
		}
	},
};
</script>

<style scoped>
.page {
	background: #282828;
}
.mine {
	position: relative;
	bottom: 10px;
	background: #212121;
}
.mine .wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.nav-bar {
	background: #212121;
}
.mine .header {
	background: #282828;
	padding: 20px 0 10px;
}
::v-deep .van-nav-bar__right {
	top: 10px;
}
::v-deep .van-nav-bar__content {
	height: 50px;
}
::v-deep .van-hairline--bottom::after {
	border-bottom-width: 0px;
}
.mine .header .van-nav-bar .van-icon {
	font-size: 20px;
}
.mine .header .user-wrapper {
	display: flex;
	align-items: center;
	margin: 0px 15px;
}
.mine .user_img {
	height: 60px;
	width: 60px;
}
::v-deep .van-loading__spinner {
	height: 50px;
	width: 50px;
}
::v-deep .van-image__error-icon {
	font-size: 30px;
}
.mine .header .user-wrapper .login-content {
	flex: 1;
	margin-left: 15px;
}
.mine .header .user-wrapper .login-content .login-btn {
	display: inline-block;
	font-size: 20px;
	line-height: 0px;
	color: #fff;
}
.mine .header .user-wrapper .login-content .login-label {
	margin-top: 0px;
	font-size: 22px;
	color: hsla(0, 0%, 100%, 0.8);
	display: inline-block;
	margin-left: 20px;
}
.mine .page-bg {
	height: 100vh;
}
.mine .content {
	position: relative;
	padding: 10px 15px 0;
	height: auto;
	background: #282828;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #ffffff;
	font-size: 16px;
	display: none !important;
}

.mine .wrapper .content .finance {
	position: absolute;
	display: flex;
	align-items: center;
	top: -28px;
	left: 20px;
	right: 20px;
	height: 65px;
	background: #282828;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .finance .line {
	width: 3px;
	height: 40px;
	background-color: #ccc;
}
.mine .wrapper .content .finance .finance-item {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.mine .wrapper .content .finance .finance-item .text {
	margin-left: 10px;
	font-size: 15px;
	color: #ddb285;
	font-weight: 500;
}
.mine .wrapper .content .finance .finance-item .icon {
	font-size: 20px;
	color: #ddb285;
}
.mine .wrapper .content .menu {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background: #282828;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .menu .menu-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 50%;
	height: 70px;
	line-height: 1.4;
}
.mine .wrapper .content .menu .menu-item:nth-child(even) {
	padding-right: 10px;
}
.mine .wrapper .content .menu .menu-item:nth-child(even) .menu-item-icon {
	margin-left: 5px;
}
.mine .wrapper .content .menu .menu-item .menu-item-label {
	font-size: 14px;
	color: #868686;
	font-weight: 500;
	width: 100%;
}
.mine .wrapper .content .menu .menu-item .menu-item-icon {
	margin: 15px 5px 15px 15px;
	width: 35px;
	height: 35px;
	-o-object-fit: contain;
	object-fit: contain;
}
.mine .wrapper .content .wallet {
	margin-top: 50px;
	padding: 0 20px;
	background: #282828;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .wallet .part-1 {
	display: flex;
	align-items: center;
	height: 50px;
}
.mine .wrapper .content .wallet .font-primary-color {
	color: #8c71d2;
	font-size: 15px;
	margin: 20px 0 5px;
}
.font-gray {
	color: #868686;
}
.mine .wrapper .content .wallet .part-2 {
	display: flex;
	align-items: center;
	height: 80px;
}
.mine .wrapper .content .wallet .part-2 .balance {
	flex: 1;
	font-size: 28px;
	color: #ddb285;
	font-weight: 700;
}
.mine .wrapper .content .wallet .van-hairline--bottom::after {
	border-bottom-width: 3px;
}
.mine .wrapper .content .wallet .part-2 .refresh-btn {
	margin-left: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	font-size: 18px;
	border-radius: 50%;
	color: #ffffff;
	background-color: #ddb285;
}
.cskh {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 18px;
	right: 81px;
	z-index: 2;
}
.cskh img {
	width: 100%;
	height: 100%;
}
.icon-language {
	margin-right: 10px;
}
.item_ {
	padding: 15px;
	background: #282828;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	color: #fff;
}
.item_ .left {
	display: flex;
	align-items: center;
	gap: 5px;
}
.item_ .right {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.item_ .left img {
	width: 25px;
}
.item_ .right img {
	width: 20px;
	margin-right: 10px;
}
.item_ .right .van-icon {
	color: #969799;
	font-size: 20px;
}
.absolute-top {
	position: absolute;
	right: 0;
	top: 10px;
	display: flex;
	gap: 10px;
	padding: 10px;
}
.absolute-top img {
	height: 25px;
}
</style>
