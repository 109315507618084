<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('heaven')" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab :title="$t('newest')">
					<van-pull-refresh border="false" class="list-wrapper" style="margin-top:10px">
						<van-grid :column-num="1">
							<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
								<div class="card">
									<div class="sm">
										<div class="girl-left">
											<div class="girl-name">{{ v.xuanfei_name }}</div>
											<div class="flex">
												<img src="/img/mine/i9.png" /> <span>{{ $t("type") }}: {{ v.type }}</span>
											</div>
											<div class="flex">
												<img src="/img/mine/i8.png" /> <span>{{ $t("about") }}: {{ v.info }}</span>
											</div>
											<div class="flex">
												<img src="/img/mine/i10.png" /> <span>{{ $t("address") }}: {{ v.address }}</span>
											</div>
										</div>
										<div class="girl-right">
											<van-image class="game_item_img" :src="v.img_url">
												<template v-slot:loading>
													<van-loading type="circular" />
												</template>
											</van-image>
											<i>{{ $t("verified") }}</i>
										</div>
									</div>
								</div>
							</van-grid-item>
						</van-grid>
					</van-pull-refresh>
				</van-tab>
				<van-tab :title="$t('city')">
					<div class="card"><div class="sm">{{ $t("text1") }}</div></div>
					<!--<br />
					<div class="address">
						<van-row type="flex" justify="" v-for="(val, key) in addlist" :key="key">
							<van-col span="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div>-->
				</van-tab>
				<van-tab :title="$t('about')">
					<div class="card">
						<div class="sm">
							<p class="rig-title">{{ $t("text2") }}</p>
							<p class="rig-content">{{ $t("text3") }}</p>
							<p class="rig-title">{{ $t("text4") }}</p>
							<p class="rig-content">{{ $t("text5") }}</p>
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: "Hồ Chí Minh",
					1: "Hà Nội",
					2: "Đà Nẵng",
					3: "Vũng Tàu",
				},
				{
					0: "Quy Nhơn",
					1: "Đà Lạt",
					2: "Hải Phòng",
					3: "Bắc Ninh",
				},
			],
			datalist: [],
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/list?id=" + data.id + "&name=" + data.name});
			}
		},
		getAddress() {
			this.$http({
				method: "get",
				url: "address_list",
			}).then(res => {
				this.addlist = res.data;
			});
		},
		profile(id) {
			this.$router.push({path: "/profile?id=" + id});
		},
		getxuanfeilist() {
			this.$http({
				method: "get",
				url: "xuanfeilist",
			}).then(res => {
				this.datalist = res.data;
				console.log(res.data);
			});
		},
	},
	created() {
		this.getxuanfeilist();
		this.getAddress();
	},
};
</script>

<style lang="less" scoped>
.page {
	background: #282828;
}
.nav-bar {
	background: #282828;
	height: 50px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-col {
	padding: 0 !important;
	width: 50%;
	text-align: center;
	color: #e1be9c;
}
::v-deep .van-nav-bar__title {
	max-width: 80%;
	margin: 0 auto;
	color: #ddb285;
	font-size: 18px;
}
::v-deep .van-nav-bar__content {
	height: 50px;
}
::v-deep .van-tabs__nav {
	background: #212121;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #282828;
}
::v-deep .van-tab {
	font-size: 16px;
	line-height: 40px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #ddb285;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 40px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
::v-deep .van-tab--active {
	color: #ddb285;
}
.card {
	background: linear-gradient(120deg, #212121, #313131);
	width: 95%;
	color: white;
	font-size: 15px;
	margin: 5px auto 10px auto;
	border-radius: 10px;
	line-height: 1.3;
}
::v-deep .van-row--flex {
	line-height: 40px;
	flex-flow: row wrap;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #e1be9c;
	font-size: 18px;
	margin: 10px 0 0;
}
.rig-content {
	font-size: 17px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
.convention-item {
	background: #282828;
	padding-bottom: 100px;
}
::v-deep .van-grid-item__content--center {
	padding: 0;
	height: auto;
	background: none;
}
::v-deep .van-grid-item__content::after, ::v-deep .van-hairline--top::after {
	display: none;
}
.rig-name {
	width: 100%;
	margin: 10px 0;
	font-size: 1.4rem;
	text-align: center;
	font-weight: bold;
	color: #ddb285;
}
.sm {
	width: 100%;
	border-radius: 10px;
	border: 1px solid #766350;
	margin: 10px 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
	position: relative;
	overflow: hidden;
}
.sm:after,
.sm:before {
	content: "";
	position: absolute;
	width: 137.4vw;
	height: 19.2vw;
	background: linear-gradient(0deg, rgba(74, 70, 70, 0.29), transparent);
	transform: rotate(139deg);
}
.sm:before {
	left: -4%;
}
.sm:after {
	left: -35%;
	z-index: 0;
}
.van-grid-item .card .sm {
	margin: 0;
	display: flex;
	flex-direction: row;
}
.van-grid-item .card .sm .girl-left {
	width: 70%;
	line-height: 1.4;
}
.van-grid-item .card .sm .girl-right {
	width: 30%;
	position: relative;
}
.girl-right .game_item_img {
	overflow: hidden;
	border-radius: 100%;
	aspect-ratio: 1;
	width: 100%;
}
.van-grid-item .card .sm .girl-right i {
	position: absolute;
	right: -45px;
	top: 5px;
	width: 120px;
	background: #ff6056;
	font-size: 11px;
	padding: 3px 10px;
	text-align: center;
	font-style: normal;
	transform: rotate(45deg);
	color: #e7e7e7;
	z-index: 1;
}
.girl-name {
	background: linear-gradient(90deg, #efcba7, #dcab76);
	color: #8d5825;
	display: inline-block;
	padding: 3px 8px;
	border-radius: 5px;
	margin-bottom: 10px;
}
.flex {
	display: flex;
	align-items: center;
	gap: 5px;
}
.flex img {
	height: 18px;
}
</style>
