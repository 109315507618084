<template>
	<div class="container page">
		<van-nav-bar :title="this.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#ddb285" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="right">
			<van-pull-refresh border="false" class="list-wrapper" v-model="isLoading">
				<van-grid :column-num="1">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
						<div class="card">
							<div class="sm">
								<div class="girl-left">
									<div class="girl-name">{{ v.xuanfei_name }}</div>
									<div class="flex">
										<img src="/img/mine/i9.png" /> <span>{{ $t("type") }}: {{ v.type }}</span>
									</div>
									<div class="flex">
										<img src="/img/mine/i8.png" /> <span>{{ $t("about") }}: {{ v.info }}</span>
									</div>
									<div class="flex">
										<img src="/img/mine/i10.png" /> <span>{{ $t("address") }}: {{ v.address }}</span>
									</div>
								</div>
								<div class="girl-right">
									<van-image class="game_item_img" :src="v.img_url">
										<template v-slot:loading>
											<van-loading type="circular" />
										</template>
									</van-image>
									<i>{{ $t("verified") }}</i>
								</div>
							</div>
						</div>
					</van-grid-item>
				</van-grid>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			vod_name: "Việt Nam",
			isLoading: false,
			datalist: [],
		};
	},
	methods: {
		back() {
			this.$router.push({path: "Choose"});
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("renew"));
				this.isLoading = false;
			}, 500);
		},
		profile(id) {
			this.$router.push({path: "/profile?id=" + id + "&name=" + this.vod_name + "&adsid=" + this.$route.query.id});
		},
		getxuanfeilist() {
			this.$http({
				method: "get",
				url: "xuanfeilist",
				data: {id: this.$route.query.id},
			}).then(res => {
				this.datalist = res.data;
			});
		},
	},
	created() {
		this.vod_name = this.$route.query.name;
		this.getxuanfeilist();
	},
};
</script>

<style scoped>
.right {
	margin-top: 10px;
}
::v-deep .van-grid-item__content--center {
	padding: 15px;
	height: auto;
	background: none;
}
::v-deep .van-grid-item__content--center {
	padding: 0;
	height: auto;
	background: none;
}
::v-deep .van-grid-item__content::after,
::v-deep .van-hairline--top::after {
	display: none;
}
.card {
	background: linear-gradient(120deg, #212121, #313131);
	width: 95%;
	color: white;
	font-size: 15px;
	margin: 20px auto 10px auto;
	border-radius: 10px;
	line-height: 1.3;
}
.rig-name {
	width: 100%;
	margin: 10px 0;
	font-size: 1.4rem;
	text-align: center;
	font-weight: bold;
	color: #ddb285;
}
.sm {
	width: 100%;
	border-radius: 10px;
	border: 1px solid #766350;
	margin: 10px 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
	position: relative;
	overflow: hidden;
}
.sm:after,
.sm:before {
	content: "";
	position: absolute;
	width: 137.4vw;
	height: 19.2vw;
	background: linear-gradient(0deg, rgba(74, 70, 70, 0.29), transparent);
	transform: rotate(139deg);
}
.sm:before {
	left: -4%;
}
.sm:after {
	left: -35%;
	z-index: 0;
}
.van-grid-item .card .sm {
	margin: 0;
	display: flex;
	flex-direction: row;
}
.van-grid-item .card .sm .girl-left {
	width: 70%;
	line-height: 1.4;
}
.van-grid-item .card .sm .girl-right {
	width: 30%;
	position: relative;
}
.girl-right .game_item_img {
	overflow: hidden;
	border-radius: 100%;
	aspect-ratio: 1;
	width: 100%;
}
.van-grid-item .card .sm .girl-right i {
	position: absolute;
	right: -45px;
	top: 5px;
	width: 120px;
	background: #ff6056;
	font-size: 11px;
	padding: 3px 10px;
	text-align: center;
	font-style: normal;
	transform: rotate(45deg);
	color: #e7e7e7;
	z-index: 1;
}
.girl-name {
	background: linear-gradient(90deg, #efcba7, #dcab76);
	color: #8d5825;
	display: inline-block;
	padding: 3px 8px;
	border-radius: 5px;
	margin-bottom: 10px;
}
.flex {
	display: flex;
	align-items: center;
	gap: 5px;
}
.flex img {
	height: 18px;
}
</style>
