<template>
	<div class="page">
		<van-nav-bar :title="this.xuanfeidata.xuanfei_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#ddb285" @click="$router.push({path: '/Choose'})" />
			</template>
		</van-nav-bar>
		<swiper class="banner_swiper" :options="bannerSwiperOption">
			<swiper-slide v-for="(v, key) in banners" :key="key">
				<van-image class="banner_img" :src="v">
					<template v-slot:loading>
						<van-loading type="circular" />
					</template>
				</van-image>
			</swiper-slide>
		</swiper>
		<div class="box">
			<div class="information">
				<div class="girl-name">{{ this.xuanfeidata.xuanfei_name }}</div>
				<div class="flex">
					<img src="/img/mine/i9.png" /> <span>{{ $t("type") }}: {{ this.xuanfeidata.type }}</span>
				</div>
				<div class="flex">
					<img src="/img/mine/i8.png" /> <span>{{ $t("about") }}: {{ this.xuanfeidata.info }}</span>
				</div>
				<div class="flex">
					<img src="/img/mine/i10.png" /> <span>{{ $t("address") }}: {{ this.xuanfeidata.address }}</span>
				</div>
				<p>{{ $t("text6") }}</p>
			</div>
			<van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			<button class="kf_btn" @click="yuyue()">{{ $t("dating") }}</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: [],
			bannerSwiperOption: {
				slidesPerView: 1,
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
			banners: [{}],
		};
	},
	methods: {
		back() {
			this.$router.push({path: "list?id=" + this.$route.query.adsid + "&name=" + this.$route.query.name});
		},
		getxuanfeidata() {
			this.$http({
				method: "get",
				url: "xuanfeidata",
				data: {id: this.$route.query.id},
			}).then(res => {
				this.xuanfeidata = res.data;
				this.banners = res.data.img_url;
			});
		},
		yuyue() {
			this.$toast(this.$t("pls_contact_dating"));
		},
	},
	created() {
		this.getxuanfeidata();
	},
};
</script>

<style>
body {
	background: #282828;
}
.container {
	display: inline-block;
}
.box {
	margin: 0 auto;
	text-align: center;
	padding: 10px;
}
.name {
	font-size: 1.125px;
}
.title {
	font-size: 0.625px;
}
.button {
	width: 10px;
	height: 2.5px;
	font-size: 0.9375px;
	margin-top: 0.625px;
}
.information {
	border-radius: 5px;
	border: 1px solid #7e6a56;
	padding: 15px;
	font-size: 14px;
	text-align: left;
	line-height: 2;
	color: #fff;
	margin-bottom: 20px;
}
.information .flex {
	display: flex;
	align-items: center;
}
.information p {
	border-top: 1px solid #eee;
	padding-top: 10px;
	margin-bottom: 0;
}
.girl-name {
	background: linear-gradient(90deg, #efcba7, #dcab76);
	color: #8d5825;
	display: inline-block;
	padding: 3px 8px;
	border-radius: 5px;
	margin-bottom: 10px;
}
.flex {
	display: flex;
	align-items: center;
	gap: 5px;
}
.flex img {
	height: 18px;
}
.kf_btn {
    width: 70%;
    height: 45px;
    background: linear-gradient(#fde3ca, #e7b486);
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
    color: #333;
    border-radius: 10px;
	margin: 20px 0 10px;
}
</style>
