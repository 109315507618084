<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar :title="$t('service')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#ddb285" @click="back()" />
				</template>
			</van-nav-bar>
		</div>
		<div class="servicelistItem">
			<div class="servicelistItemTop">
				<img class="servicelistItemImage" src="img/mine/kefu.png" />
				<div class="servicelistItemText">{{ $t("service") }}</div>
				<div class="servicelistItemBtn" @click="toServicePage()">
					<div class="servicelistItemBtnText">
						{{ $t("connect") }}
					</div>
				</div>
			</div>
			<div class="servicelistItemBottom">
				<div class="servicelistItemInfoText">
					{{ $t("text_service") }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {
		back() {
			return window.history.back();
		},
		toServicePage() {
			this.$router.push("ServicePage");
		},
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.servicelistItem {
	display: flex;
	flex-direction: column;
	height: 200px;
	padding: 30px 30px;
	margin: 30px 20px;
	border-radius: 20px;
	justify-content: space-between;
	background: #fff;
}
.servicelistItem .servicelistItemTop {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100px;
}
.servicelistItem .servicelistItemTop .servicelistItemImage {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.servicelistItem .servicelistItemTop .servicelistItemText {
	margin-left: 10px;
	font-size: 25px;
	font-weight: 700;
	color: #000;
	flex: 1;
}
.servicelistItem .servicelistItemTop .servicelistItemBtn {
	display: flex;
	width: 80px;
	height: 35px;
	border-radius: 25px;
	align-items: center;
	justify-content: center;
	background: linear-gradient(#fde3ca, #e7b486);
	color: #8a5623;
}
.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText {
	font-size: 13px;
}
.servicelistItem .servicelistItemBottom {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	background: #eee;
	border-radius: 10px;
	color: #979799;
}
.servicelistItem .servicelistItemBottom .servicelistItemInfoText {
	font-size: 16px;
}
</style>
